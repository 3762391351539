// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// Create the apiStatus slice
export const apiStatus = createSlice({
  name: 'apiStatus',
  initialState: {
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    }
  }
})

// Export the action
export const { setLoading } = apiStatus.actions

// Export the reducer
export default apiStatus.reducer
