import mock from '../mock'
import getData from '../apiCall'

mock.onGet('/api/get_samples').reply(async config => {
    let params
    if (!config.country && !config.state) {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId }
    } else {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId, country: config.country, state: config.state }
    }
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/get_all_samples?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, config.headers.Authorization, params)
    if (config.userId) {
        const newReponse = {
            ...res,
            data: {
                ...res.data,
                samples: res.data.samples.map(obj => {
                    return { ...obj, userId: config.userId }
                })
            }
        }
        return [200, newReponse.data]
    }
    return [200, res.data]
})
mock.onGet('/api/get_samples_pro').reply(async config => {
    let params
    if (!config.country && !config.state) {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId }
    } else {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId, country: config.country, state: config.state }
    }
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/get_all_samples_pro?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, config.headers.Authorization, params)
    if (config.userId) {
        const newReponse = {
            ...res,
            data: {
                ...res.data,
                samples: res.data.samples.map(obj => {
                    return { ...obj, userId: config.userId }
                })
            }
        }
        return [200, newReponse.data]
    }
    return [200, res.data]
})

mock.onGet('/admin/deleted_samples').reply(async config => {
    let params
    if (!config.value) {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId }
    } else {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId, keyword: config.value }
    }
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/admin/deleted_samples?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, config.headers.Authorization, params)
    if (config.value) {
        return [200, res]
    }
    if (config.userId) {
        const newReponse = {
            ...res,
            data: {
                ...res.data,
                samples: res.data.samples.map(obj => {
                    return { ...obj, userId: config.userId }
                })
            }
        }
        return [200, newReponse.data]
    }
    return [200, res.data]
})
mock.onGet('/admin/deleted_samples_pro').reply(async config => {
    let params
    if (!config.value) {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId }
    } else {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, userId: config.userId, keyword: config.value }
    }
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/admin/deleted_samples_pro?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, config.headers.Authorization, params)
    if (config.value) {
        return [200, res]
    }
    if (config.userId) {
        const newReponse = {
            ...res,
            data: {
                ...res.data,
                samples: res.data.samples.map(obj => {
                    return { ...obj, userId: config.userId }
                })
            }
        }
        return [200, newReponse.data]
    }
    return [200, res.data]
})