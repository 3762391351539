import mock from '../mock'
import moment from 'moment'

// ** Utils
import { paginateArray } from '../utils'

// POST method implementation:
async function getData(url = '', auth_token) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth_token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
  const param = response.json()
  return param// parses JSON response into native JavaScript objects
}

async function postData(url = '', data, auth_token) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth_token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
  const param = response.json()
  return param// parses JSON response into native JavaScript objects
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/issues/list').reply(async config => {
  // console.log("params form mock: ", config)
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sort, sortColumn, userId = null  } = config

  /* eslint-enable */
  const data = await getData(`${process.env.REACT_APP_API_URL}/complaints?complaint_status=${config.complaint_status}`, config.headers.Authorization)
  if (userId) {
    const test = data
    test.data = data.data.filter(value => {
      if (value.user_id) return value.user_id._id === userId
      else return false
    })
  }

  const dataAsc = data.data.sort((a, b) => {
    if (a[sortColumn] !== null && b[sortColumn] !== null) {
      let valueA = a[sortColumn] 
      let valueB = b[sortColumn]
      if (typeof a[sortColumn] === 'string') {
        valueA = a[sortColumn].toLowerCase()
        valueB = b[sortColumn].toLowerCase()
      }
      return valueA < valueB ? -1 : 1
    } else {
      return a[sortColumn] === null ? -1 : 1
      // const splitColumn = sortColumn.split('.')
      // const columnA = a[splitColumn[0]][splitColumn[1]]
      // const columnB = b[splitColumn[0]][splitColumn[1]]
      // if (typeof columnA === 'string') {
      //   columnA = columnA.toLowerCase()
      //   columnB = columnB.toLowerCase()
      // }
      // return columnA < columnB ? -1 : 1
    }
  })
  
  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const queryLowered = q.toLowerCase()
  
  const filteredData = dataToFilter.filter(data => {
      return (
        (
          data.complaint_status.toLowerCase().includes(queryLowered) ||
          data.message.toLowerCase().includes(queryLowered) ||
          String(data.complaintIdSeq).toLowerCase().includes(queryLowered) ||
          (data.fullname ? data.fullname.toLowerCase().includes(queryLowered) : false) ||
          moment(data.createdAt).format('MM/DD/YYYY h:mm a').toLowerCase().includes(queryLowered) ||
          
          data.complaint_status.toLowerCase().startsWith(queryLowered) ||
          data.message.toLowerCase().startsWith(queryLowered) ||
          String(data.complaintIdSeq).toLowerCase().startsWith(queryLowered) ||
          (data.fullname ? data.fullname.toLowerCase().startsWith(queryLowered) : false) ||
          moment(data.createdAt).format('MM/DD/YYYY h:mm a').toLowerCase().startsWith(queryLowered)
        )
      )
    }
  )

  /* eslint-enable  */
  return [
    200,
    {
      allData: data.data,
      total: filteredData.length,
      invoices: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

mock.onGet('/apps/invoice/allInvoices').reply(async config => {
  // eslint-disable-next-line object-curly-newline
  const {id} = config
  const _id = id.id
  /* eslint-enable */
  const data = await getData(`${process.env.REACT_APP_API_URL}/complaints`, config.headers.Authorization)
  const index = data.data.findIndex(e => e._id === _id)
  const res = data.data[index]
  /* eslint-enable  */

  return [200, { res }]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply(async config => {
 
  const invoiceId = config.url.substring(config.url.lastIndexOf('/') + 1)
  
  const data = await getData(`${process.env.REACT_APP_API_URL}/complaints`, config.headers.Authorization)
  
  const invoiceIndex = data.data.findIndex(e => e._id === invoiceId)
  
  const responseData = {
    invoice: data.data[invoiceIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  
  return [200, responseData]
})

// ------------------------------------------------
// UPDATE: Update Status
// ------------------------------------------------
mock.onPost('/apps/statusChange').reply(async config => {
  //Get parameters
  const { id, value } = JSON.parse(config.data)
  // Parameter to be passed to post api as body
  const param = {id, complaint_status: value.value}
  //Change Status Api Call
  const data = await postData(`${process.env.REACT_APP_API_URL}/complaint/change_status`, param, config.headers.Authorization)
  //return response
  return [200, data]
})

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply(config => {
  // Get invoice id from URL
  let invoiceId = config.id

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(t => t.id === invoiceId)
  data.invoices.splice(invoiceIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
