import mock from '../mock'
import { getUserInformation, getData } from '../apiCall'

mock.onGet('/api/get_users').reply(async config => {
    let params
    if (!config.country && !config.state) {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), extractType: config.extractType === "All" ? "" : config.extractType, startDate: config.startdate, endDate: config.enddate }
    } else {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), extractType: config.extractType === "All" ? "" : config.extractType, startDate: config.startdate, endDate: config.enddate, country: config.country, state: config.state }
    }
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/get_all_users?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, config.headers.Authorization, params)
    return [200, res.data]
})
mock.onGet('/api/get_users_pro').reply(async config => {
    let params
    if (!config.country && !config.state) {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), extractType: config.extractType === "All" ? "" : config.extractType, startDate: config.startdate, endDate: config.enddate }
    } else {
        params = { skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), extractType: config.extractType === "All" ? "" : config.extractType, startDate: config.startdate, endDate: config.enddate, country: config.country, state: config.state }
    }
    const res = await getData('POST', `${process.env.REACT_APP_API_URL}/get_all_users_pro?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, config.headers.Authorization, params)
    return [200, res.data]
})

mock.onGet('/api/get_user_information').reply(async config => {
    const params = { userId: config.userId }
    const res = await getUserInformation('GET', `${process.env.REACT_APP_API_URL}/get_information?userId=${params.userId}`, config.headers.Authorization)
    return [200, res.data]
})
